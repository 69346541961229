import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Testimonial from './testimonial'

const quoteSize = 250

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialQuery {
        allTestimonialsYaml(limit: 5) {
          edges {
            node {
              name
              title
              quote
            }
          }
        }
      }
    `}
    render={(data) => {
      const testimonials = data.allTestimonialsYaml.edges

      return (
        <div>
          {testimonials.map(t => (
            <Testimonial key={t.node.name} testimonial={t.node} quoteSize={quoteSize} />
          ))}
        </div>
      )
    }}
  />
)
