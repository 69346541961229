import React from 'react'
import PropTypes from 'prop-types'

import './flex.scss'

export default function Flex ({
  children, className, mobileReverse, style = {}
}) {
  const classes = ['container-flex-col2row']
  if (className) {
    classes.push(className)
  }

  if (mobileReverse) {
    classes.push('container-flex-col2row--reverse')
  }
  return <div className={classes.join(' ')} style={style}>{children}</div>
}

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mobileReverse: PropTypes.bool
}

Flex.defaultProps = {
  className: '',
  mobileReverse: false
}
