import React from 'react'
import PropTypes from 'prop-types'

import './testimonial.scss'

export default class Testimonial extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hidden: true }
    this.toggleHidden = this.toggleHidden.bind(this)
  }

  toggleHidden () {
    const { hidden } = this.state
    this.setState({ hidden: !hidden })
  }

  render () {
    const { hidden } = this.state
    const { testimonial, quoteSize } = this.props
    const { quote } = testimonial
    let quoteNodes

    if (hidden && quote.length > quoteSize) {
      quoteNodes = (
        <p>
          {`${quote
            .split('\n')[0]
            .substr(0, quoteSize - 3)}... `}
          <a onClick={this.toggleHidden} style={{ fontSize: '16px' }}>read more</a>
        </p>
      )
    } else {
      quoteNodes = quote.split('\n').map(q => <p>{q}</p>)
      if (quote.length > quoteSize) {
        quoteNodes.push(
          <a onClick={this.toggleHidden} style={{ fontSize: '16px' }}>see less </a>
        )
      }
    }

    return (
      <div>
        <div className='testimonial-qt-text'>{quoteNodes}</div>
        <div className='testimonial-align-rigth'>
          <div style={{ fontWeight: 'bold' }}>{testimonial.name}</div>
          <div style={{ fontSize: '16px' }}>{testimonial.title}</div>
        </div>
      </div>
    )
  }
}

Testimonial.propTypes = {
  quoteSize: PropTypes.number.isRequired,
  testimonial: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired
  }).isRequired
}
