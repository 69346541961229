import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoachingText from '../components/remote-coaching-text'
import './remote-coaching.scss'
import RemoteTestimonials from '../components/remote-testimonials-text'

import CoachingImg from '../images/remote-coaching.png'
import dolarIcon from '../images/dollar_black_48x48.png'
import Flex from '../components/flex'

export default function RemoteCoaching () {
  return (
    <Layout>
      <SEO title='Remote Coaching' />
      <div className='remote-background-body'>
        <div className='remote-head-mobile'>
          <h1 className='remote-title-mobile'>Remote Coaching</h1>
        </div>
        <div style={{ maxWidth: '90%', margin: 'auto' }}>
          <Flex>
            <div className='remote-content-left-col'>
              <div className='remote-head'>
                <h1>Remote Coaching</h1>
              </div>
              <CoachingText />
              <div className='remote-information-container'>
                <div className='flex-row' style={{ alignItems: 'center' }}>
                  <span className='material-icons' style={{ marginRight: '0.5em' }}>phone</span>
                  <p style={{ fontWeight: 'bold' }} className='remote-information-small-font'>(443) 413-7866</p>
                </div>
                <div className='flex-row' style={{ alignItems: 'center' }}>
                  <span className='material-icons' style={{ marginRight: '0.5em' }}>email</span>
                  <p style={{ margin: '0', fontWeight: 'bold' }}>info@coachmillerseminars.com</p>
                </div>
              </div>
            </div>
            <div className='remote-content-rigth-col'>
              <img src={CoachingImg} width='360' height='400' alt='desk with a notebook and computer' style={{ marginTop: '40px' }} className='remote-image-small remote-image-mobile-hide' />
              <div className='remote-price-container'>
                <div style={{ marginRight: '0.5em' }}>Remote Coaching</div>
                <img src={dolarIcon} alt='dolar icon' height='48' width='48' style={{ height: '1.5em', width: '1.5em', marginRight: '1px' }} />
                <div style={{ fontSize: '2em', fontWeight: 'bold', marginRight: '4px' }}>169</div>
                <div>
                  <span style={{ fontWeight: 'bold', marginRight: '4px' }}>/month</span>
                </div>
              </div>
            </div>
          </Flex>
          <h2 className='remote-testimonial-title'>Testimonials</h2>
          <div style={{ height: '32px' }} />
          <RemoteTestimonials />
        </div>
        <div style={{ height: '64px' }} />
      </div>
    </Layout>
  )
}
