import React from 'react'

export default function CoachingText () {
  return (
    <div>
      <p>
        The Coach-Athlete relationship is a key factor for an athletes
        success. Designing your exercise prescription is but a small
        piece of the equation. The connection between Coach and Athlete is
        defined by quality of understanding, respect and trust.

      </p>
      <p>
        Hiring CoachMiller you’ll receive an experienced mentor who will author your
        training plans, analyze your videos and respond with detailed
        instructions/diagrams geared to maximize your performance. Remote
        Coaching involves individualized, adjustable programming, a weekly
        phone call, email or text message review of technique videos.
        Competition Coaching, FaceTime/Skype and in-person Training
        Sessions are all available for an additional charge.

      </p>
    </div>
  )
}
